import { useState, useEffect } from 'react';
import invariant from 'invariant';

/**
 * Ensures that `webpack.definePlugin` or `babel-plugin-transform-define` have
 * done their job by replacing `boolean` environment variables with actual
 * booleans, and not strings.
 * @throws If there is a problem, likely due to a configuration or coding error.
 */const ensureEnvVariablesWereWellReplaced=()=>{invariant(!0,"(`ensureEnvironmentVariablesCorrectlyReplaced`) `IS_PRODUCTION` is not a boolean (given true), check `@dbh/environment-variables`"),invariant(!0,"(`ensureEnvironmentVariablesCorrectlyReplaced`) `IS_PRODUCTION_WWW` is not a boolean (given true), check `@dbh/environment-variables`");};var ensureEnvVariablesWereWellReplaced$1 = ensureEnvVariablesWereWellReplaced;

// to mock the `SSR` environment when needed.
const canUseDOM=!!("undefined"!=typeof window&&window.document&&window.document.createElement);// Be careful when using this and check if you really need to use it. If you
// create two different behaviors you will have to test and maintain both.
// Moving it to a separated file and re-exporting it, could make some tests fail.
// @see `mockIsServerSideRendering`.
const isServerSideRendering=()=>!canUseDOM;// Shim the `Element` class on the server so that it can be used as
// a `propType` for certain cases, for example for `propTyping` a `ref`.
const Element=isServerSideRendering()?()=>{}:window&&window.Element;// Shim the `HTMLElement` class on the server so that it can be used as
// a `propType` for certain cases, for example for `propTyping` a `ref`.
const HTMLElement=isServerSideRendering()?()=>{}:window&&window.HTMLElement;const isNonProductionImageOnProductionWWW=()=>!isServerSideRendering()&&window.location.host.includes("www.")&&!1;/**
 * Returns "debug data" that has been set during the build process or at runtime
 * using environment variables.
 * @return {Object} The "debug data".
 */const getDebugData=()=>({DBH_GIT_COMMIT:"e9b786065f30ec436eb44351b2035bc88199c19d",DBH_BUILD_DATE:"2024-09-28T02_54_51",DBH_VERSION_CODE:"2024-09-28T02_54_51-e9b786065f30ec436eb44351b2035bc88199c19d-production-bitbucket-8316"});const getCurrentSsrDate=()=>new Date;// Moving it to a separated file and re-exporting it, could make some tests fail.
// @see `mockIsServerSideRendering`.
const useIsServerSideRendering=()=>{const[a,b]=useState(isServerSideRendering());return useEffect(()=>{b(isServerSideRendering());},[a,b]),a};

export { Element, HTMLElement, ensureEnvVariablesWereWellReplaced$1 as ensureEnvVariablesWereWellReplaced, getCurrentSsrDate, getDebugData, isNonProductionImageOnProductionWWW, isServerSideRendering, useIsServerSideRendering };
